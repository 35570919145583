import { createApp, h } from 'vue'

// Import Router setup (remove import and router from "vueSettings" when not needed)
import router from './router/'

// Add Vue instance to every element with the 'vue' attribute
document.querySelectorAll('[vue]').forEach(async (el) => {
    var vueSettings = {
        delimiters: ['${', '}'],
    }

    // If 'vue' attribute is present, initialize it as component or load as settings
    if (el.getAttribute('vue') !== '') {
        try {
            var vueAttributeObject = JSON.parse(el.getAttribute('vue'))
        } catch (e) {
            // Continue, no valid Json
        }

        // Got vue object, use as data and merge them with the settings
        if (typeof vueAttributeObject === 'object') {
            vueSettings = {
                ...vueSettings,
                data: () => vueAttributeObject,
            }

            createApp(vueSettings)
                // Connect Router setup
                .use(router)
                .mount(el)
            return
        }

        var attrs = {}
        Object.values(el.attributes).forEach(function (value) {
            if (value.name === 'vue') {
                // Ignore 'vue' attribute, which is already parsed as the component name
                return
            }

            if (value.name.charAt(0) !== ':') {
                // No ':' prefixed, handle as attribute
                attrs[value.name] = value.value
            } else {
                // Handling properties
                try {
                    attrs[value.name.slice(1)] = JSON.parse(value.value)
                } catch (e) {
                    // Not a Json value, we have a string
                    attrs[value.name.slice(1)] = value.value
                }
            }
        })

        const defaultSlot = el.innerHTML

        // Dynamically load the component
        const component = await import('./' + el.getAttribute('vue'))
        vueSettings = {
            ...vueSettings,
            // Create the component and pass all attributes
            render: () =>
                h(component.default, attrs, {
                    default: () => defaultSlot,
                }),
        }
    }

    const app = createApp(vueSettings, attrs)
    // Temporary setting until Vue 3.3 is released then it will be the default
    app.config.unwrapInjectedRef = true
    app.config.globalProperties.window = window
    // Connect Router setup
    app.use(router).mount(el)
})
